import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { object } from 'prop-types';
import queryString from 'query-string';

import useCanShareReward from 'hooks/use-can-share-reward';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import SkeletonSubscriptionView from 'views/subscription/skeleton';

import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';
import { getPassesById } from 'state/selectors';

const LazySharePage = loadable(() => import('suspended-page/loyalty-card/share'));

const SharePage = ({ location }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const [title, setTitle] = useState('');
  const { canShare, canShareRewardLoading } = useCanShareReward();

  const {
    homePageOnly = () => {},
    notSubscribed = () => {},
  } = useNavigationContext();

  const authenticated = isAuthenticated();

  useEffect(() => {
    const onFindPass = async () => {
      const { name = '' } = await getPassesById(passId);
      setTitle(name);
    };
    onFindPass();

    // eslint-disable-next-line
  }, []);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (!canShare) {
    notSubscribed();
    return (<SkeletonSubscriptionView />);
  }

  return (<LazySharePage passId={passId} title={title} loading={canShareRewardLoading}/>);
};

SharePage.propTypes = {
  location: object,
};

export default SharePage;
